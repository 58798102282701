import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import * as React from "react";
import { useEffect, useMemo, useState } from "react";
import {
  Error, Loading, useDataProvider, useNotify,
  useRefresh
} from "react-admin";
import { httpClient } from '../../provider/dataProvider';

const EventTagsEdit = ({ record }) => {
  const dataProvider = useDataProvider();
  const [eventTags, setEventTags] = useState([]);

  const [currentEventTags, setCurrentEventTags] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const notify = useNotify();
  const refresh = useRefresh();
  const originalEventTags = useMemo(() => {
    return record.tags?.map(t => t.uid) || []
  }, [record.tags]);
  useEffect(() => {
    setCurrentEventTags(originalEventTags)
  }, [originalEventTags])
  useEffect(() => {
    dataProvider
      .getList("tags", {
        pagination: {
          page: 1,
          perPage: 100,
        },
        sort: {
          field: 'name',
          order: 'ASC'
        },
        filter: {
          type: "master",
        },
      })
      .then(({ data }) => {
        setEventTags(data.sort((a, b) => {
          if (['Arts', 'Check in', 'Market', 'Tour'].includes(a.name)) return -1;
          return a.name - b.name
        }));
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setError(error);
        setLoading(false);
      });
  }, []);

  const selectTag = React.useCallback(
    (e) => {
      const target = e.target;
      const value = target.type === "checkbox" ? target.checked : target.value;
      const id = parseInt(target.value);
      console.log("selectTag", { e, target, id });
      if (value && !currentEventTags.includes(id)) {
        setCurrentEventTags([id, ...currentEventTags]);
      } else if (!value && currentEventTags.includes(id)) {
        setCurrentEventTags(currentEventTags.filter((tag) => tag !== id));
      }
    },
    [currentEventTags, setCurrentEventTags]
  );

  const onSave = React.useCallback(
    async (event) => {
      console.log("onSave", { event, currentEventTags, originalEventTags });
      await httpClient(`${process.env.REACT_APP_REST_SERVER_URL || ''}/eventTags`, {
        method: 'POST',
        body: JSON.stringify({ eventUid: record.uid, tagUids: currentEventTags }),
      }).then(({ json }) => {
        console.log('response', json);
        notify("Updated categories");
        refresh();
      }).catch((reason) => {
        console.error(reason);
        notify("Fail to updated categories")
      })
    },
    [currentEventTags, originalEventTags]
  );

  console.log("EventTagsEdit", { eventTags, currentEventTags, originalEventTags });

  if (loading) return <Loading />;
  if (error) return <Error error={error} />;
  if (!currentEventTags) return null;

  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
      }}
    >
      {eventTags &&
        currentEventTags &&
        eventTags.map((et, index) => {
          return (
            <div
              key={`et-${index}`}
              style={{ minWidth: "150px", flex: "1 1 0px" }}
            >
              <FormControlLabel
                value={et.id}
                control={
                  <Checkbox
                    color="primary"
                    checked={currentEventTags.includes(et.id)}
                    onChange={selectTag}
                  />
                }
                label={et.name}
                labelPlacement="end"
              />
            </div>
          );
        })}
      <Button
        variant="contained"
        color="primary"
        style={{ width: "100%" }}
        onClick={onSave}
      >
        Save Categories
      </Button>
    </div>
  );
};
export default EventTagsEdit;
