export const statusList = [
  { id: 'pending', name: 'Pending' },
  { id: 'approved', name: 'Approved' },
  { id: 'unapproved', name: 'Unapproved' },
  { id: 'cancelled', name: 'Cancelled' },
  { id: 'invalid', name: 'Invalid' },
  { id: 'expired', name: 'Expired' },
];

export const platformList = [
  { id: 'Ulifestyle', name: 'Ulifestyle' },
  { id: 'Cvm', name: 'Central Harbourfront Event Space' },
  { id: 'DiscoverHK', name: 'DiscoverHK' },
  { id: 'EventHK', name: 'EventHK' },
  { id: 'Hkpl', name: 'HK Public Library' },
  { id: 'Hkppltravel', name: 'HK People Travel' },
  { id: 'Museums', name: 'LCSD Museums' },
  { id: 'MPlus', name: 'MPlus' },
  { id: 'Playwhat', name: 'Playwhat' },
  { id: 'PMQ', name: 'PMQ' },
  { id: 'Socialcareer', name: 'Social Career' },
  { id: 'TaiKwun', name: 'Tai Kwun' },
  { id: 'TheHoneyCombers', name: 'The Honey Combers' },
  { id: 'Theloophk', name: 'The LoopHK' },
  { id: 'TheMill', name: 'The Mills' },
  { id: 'Timable', name: 'Timable' },
  { id: 'Timeout', name: 'Timeout' },
  { id: 'WeekendHK', name: 'WeekendHK' },
  { id: 'Westkowloon', name: 'West Kowloon' },
  { id: 'AsiaSociety', name: 'Asia Society' },
  { id: 'HolidaySmart', name: 'Holiday Smart' },
  { id: 'Playtimes', name: 'Playtimes' },
  { id: 'Klook', name: 'Klook' },
  { id: 'Gpt', name: 'Gpt' }
].sort((a, b) => a.name < b.name ? -1 : 1);

export const langList = [
  { id: 'TC', name: '中文' },
  { id: 'EN', name: 'English' },
  { id: 'SC', name: '簡体' },
];

export const enrolTypeList = [
  { id: 'no', name: 'No' },
  { id: 'playwhat', name: 'Playwhat' },
  { id: 'url', name: 'URL' },
]

export const eventFrequencyTypeList = [
  { id: 'onetime', name: 'Onetime' },
  { id: 'repeat', name: 'Repeat' },
]

export const eventFrequencySettingList = [
  { id: 'daily', name: 'Daily' },
  { id: 'weekly', name: 'Weekly' },
  { id: 'monthly', name: 'Monthly' },
]

export const targetAudiencesList = [
  { id: 'single', name: 'Single' },
  { id: 'couple', name: 'Couple' },
  { id: 'family', name: 'Family' },
  { id: 'tourist', name: 'Tourist' },
]
