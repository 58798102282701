import React from 'react';
import { Pagination } from 'react-admin';
import { Create, Datagrid, DateField, DateTimeInput, EditButton, FormTab, List, NumberField, useRecordContext, NumberInput, SelectInput, TabbedForm, TextField, TextInput } from 'react-admin';
import EventListActions from './listAction';
import EventFilter from './listFilter';

export { EventEdit } from './EventEdit';
export { EventShow } from './EventShow';
const EventPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} perPage={50} />;
const ImagesCountField = ({ source }) => {
  const record = useRecordContext();
  return (<span style={{ color: 'purple' }}>{record && record[source]?.length}</span>);
};
export const EventList = props => {
  return (
    <List
      {...props}
      filters={<EventFilter />}
      filterDefaultValues={{
        status: 'pending',
        // language: 'TC',
      }}
      bulkActionButtons={false}
      perPage={50}
      pagination={<EventPagination />}
      rows
      sort={{ field: 'updated_datetime', order: 'DESC' }}
      actions={<EventListActions {...props} />}
    >
      <Datagrid rowClick="show" >
        <TextField source="uid" />
        <TextField source="slug" />
        <TextField source="title" />
        {/* <TextField source="desc" /> */}
        <DateField source="start_datetime" showTime />
        <DateField source="end_datetime" showTime />
        {/* <TextField source="duration" /> */}
        {/* <TextField source="logo_url" /> */}
        <TextField source="status" />
        {/* <TextField source="is_online_event" /> */}
        {/* <TextField source="enrol_start_date" /> */}
        {/* <TextField source="enrol_end_date" /> */}
        {/* <NumberField source="price" /> */}
        {/* <TextField source="currency" /> */}
        {/* <TextField source="enrol_link" /> */}
        {/* <DateField source="organizer_uid" /> */}
        {/* <ReferenceField source="venue_uid" reference="venues">
                <TextField source="name" />
            </ReferenceField> */}
        {/* <TextField source="data_source" /> */}
        {/* <TextField source="event_url" /> */}
        {/* <TextField source="platform_source" /> */}
        {/* <TextField source="platform_sequence" /> */}
        {/* <TextField source="platform_created_datetime" /> */}
        {/* <TextField source="platform_updated_datetime" /> */}
        <NumberField source="score" />
        <ImagesCountField label='Images #' source="gallery_images" />
        <DateField source="created_datetime" showTime />
        <DateField source="updated_datetime" showTime />
        {/* <TextField source="wordpress_uid" /> */}
        {/* <TextField source="wordpress_updated_datetime" /> */}
        {/* <TextField source="wordpress_post_uid" /> */}
        {/* <TextField source="gallery_images" /> */}
        {/* <TextField source="language" /> */}
        {/* <TextField source="reject_reason" /> */}
        {/* <TextField source="ical" /> */}
        {/* <TextField source="datetime_string" /> */}
        {/* <TextField source="datetime_array" /> */}
        {/* <TextField source="hashtags" /> */}
        {/* <TextField source="parentUid" /> */}
        {/* <EditButton label='Edit Admin-Only Fields' value='' /> */}
      </Datagrid>
    </List>
  )
};

export const EventCreate = props => (
  <Create submitOnEnter={false}  {...props}>
    <TabbedForm>
      <FormTab label="basic">
        <TextInput fullWidth source="title" />
        <TextInput multiline fullWidth source="desc" />
        <DateTimeInput source="start_datetime" />
        <DateTimeInput source="end_datetime" />
        <TextInput fullWidth source="logo_url" type="url" />
        <TextInput multiline fullWidth source="gallery_images" />
        <NumberInput source="price" />
        <TextInput source="currency" />
        <TextInput fullWidth source="event_url" type="url" />
        <TextInput source="platform_source" />
        <SelectInput source="language" choices={[
          { id: 'TC', name: '中文' },
          { id: 'EN', name: 'English' },
          { id: 'SC', name: '簡体' },
        ]} />
      </FormTab>
      <FormTab label="relationship">
        <TextInput source="organizer_uid" />
        {/* <ReferenceInput source="organizer_uid" reference="organizers">
                    <SelectInput optionText="uid" />
                </ReferenceInput> */}
        <TextInput source="venue_uid" />
        {/* <ReferenceInput source="venue_uid" reference="venues">
                    <SelectInput optionText="uid" />
                </ReferenceInput> */}
        <TextInput source="parentUid" />
        {/* <ReferenceInput source="parentUid" reference="events">
                    <SelectInput optionText="uid" />
                </ReferenceInput> */}
      </FormTab>
      <FormTab label="other">
        <TextInput source="status" />
        <TextInput source="reject_reason" />
        <NumberInput source="staffScoreAdjustment" min={0} max={100} step={1} />
        <NumberInput disabled source="score" helperText="score breakdown refer to https://playwhat.atlassian.net/secure/RapidBoard.jspa?rapidView=1&projectKey=PW&modal=detail&selectedIssue=PW-13" />
        <TextInput disabled source="ical" />
        <TextInput disabled source="datetime_string" />
        <TextInput multiline disabled source="datetime_array" />
      </FormTab>
      <FormTab label="optional">
        <TextInput source="hashtags" />
      </FormTab>
      <FormTab label="enrolment">
        <DateTimeInput source="enrol_start_date" />
        <DateTimeInput source="enrol_end_date" />
        <TextInput source="enrol_link" />
      </FormTab>
    </TabbedForm>
  </Create>
);

